<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>巡检日期：</span>
            <el-date-picker v-model="dateArr"
                            type="daterange"
                            size="large"
                            placeholder="请选择日期"
                            value-format="YYYY-MM-DD"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="dateChange"/>
          </div>
          <div class="marginLeft10">
            <span>巡检人：</span>
            <el-select v-model="pageInfo.userId"
                       filterable
                       class="room-select-tl"
                       placeholder="搜索巡检员"
                       size="large"
                       remote
                       :remote-method="getUserList"
                       @change="selectChange">
              <el-option v-for="item in teacherList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              />
              <template #prefix>
                <div>
                  {{ teacher.code }}
                </div>
              </template>
            </el-select>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">灭火器每日检查</div>
          <div class="flex">
            <!-- 录入题目 -->
            <button class="addBtn marginLR10" type="button" @click="handleExport">导出</button>
            <button class="addBtn marginLR10" type="button" @click="showEdit(null)"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column v-for="(field, index) in fields" :key="index" :label="field.label" :prop="field.key"
                           :width="field.width || ''"
                           :fixed="field.fixed"
                           :sortable="field.sortable ? 'custom' : false"
                           :align="field.align ? field.align : (field.width && field.width < 130 ? 'center' : 'left')">
            <template #default="scope">
              <column-content :row="scope.row" :field="field"></column-content>
            </template>
          </el-table-column>
          <el-table-column label="现场照片">
            <template #default="{row}">
              <el-button type="text" @click="viewImage(row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{row}">
              <el-button type="text" v-if="$root.isToday(row.time)" @click="showEdit(row)">编辑</el-button>
              <span v-else>——</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!-- 分页 -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <image-list-show ref="imageListShow"></image-list-show>
  </div>
</template>

<script>
import {getExtinguisherPage, getTeacherList} from "@/api/examination";
import ColumnContent from "@/utils/ColumnContent.js";
import {projectName} from "@/utils/config";
import ImageListShow from "@/view/patrolInspection/ImageListShow";
import {exportFile} from "@/api/api";

export default {
  name: 'fireHydrantsList',
  components: {ImageListShow, ColumnContent},
  data() {
    return {
      tableData: [],
      pageInfo: {
        limit: 10,
        page: 1,
        total: 0,
        // -------------------
        startDate: '',
        endDate: '',
        userId: ''
      },
      dateArr: [],
      fields: [
        {label: '编号', key: 'id', width: 80},
        {label: '巡检日期', key: 'date'},
        {
          label: '实验室',
          render: ({laboratory}, h) => h('div', laboratory.name)
        },
        {label: '灭火箱数量', key: 'fireBox'},
        {label: '灭火器', key: 'fireExtinguisher'},
        {
          label: '压力情况(绿色段)',
          render: ({pressure: status}, h) => h('div', status == 1 ? '合格' : '不合格')
        },
        {
          label: '外观完整性',
          render: ({appearance: status}, h) => h('div', status == 1 ? '合格' : '不合格')
        },
        {
          label: '喷嘴完整性',
          render: ({spray: status}, h) => h('div', status == 1 ? '合格' : '不合格')
        },
        {
          label: '保险栓',
          render: ({insure: status}, h) => h('div', status == 1 ? '合格' : '不合格')
        },
        {
          label: '时效性',
          render: ({ageing: status}, h) => h('div', status == 1 ? '合格' : '不合格')
        },
        //  ---
        {
          label: '巡检人',
          render: ({teacher}, h) => h('div', teacher.name)
        },
        {label: '备注', key: 'notes'},
        {
          label: '创建人',
          render: ({user}, h) => h('div', user.name)
        },
        {label: '创建时间', key: 'time'},
      ],
      // ------------------------------------
      teacherList: [],
      teacher: {},
      token: localStorage.getItem('token'),
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleExport() {
      exportFile(`/xj-fire-extinguisher/export?startDate=${this.pageInfo.startDate}&endDate=${this.pageInfo.endDate}&userId=${this.pageInfo.userId}&Authorization=Bearer ${this.token}`)
    },
    dateChange(e) {
      if (e) {
        this.pageInfo.startDate = e[0]
        this.pageInfo.endDate = e[1]
      } else {
        this.pageInfo.startDate = ''
        this.pageInfo.endDate = ''
      }
    },
    /**
     * 查看现场照片
     */
    viewImage(item) {
      const {img} = item
      const imgArr = []
      if (img) {
        img.split(',').forEach(iter => imgArr.push(projectName + iter))
      }
      this.$refs.imageListShow.setPhotos(imgArr)
    },
    selectChange(e) {
      this.teacher = this.teacherList.find(iter => iter.id == e) || {}
    },
    getUserList(keyword) {
      if (keyword) {
        getTeacherList({name: keyword}).then(res => {
          if (res.success) {
            this.teacherList = res.data
          } else {
            this.$root.ElMessage.error(res.message)
          }
        })
      }
    },
    //--------------------------
    reset() {
      this.dateArr = []
      const config = ['limit', 'page', 'total']
      for (const key in this.pageInfo) {
        if (!config.includes(key)) {
          this.pageInfo[key] = ''
        }
      }
      this.teacher = {}
    },
    search() {
      this.pageInfo.limit = 10;
      this.pageInfo.page = 1;
      this.getList();
    },
    getList() {
      getExtinguisherPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      })
    },
    /**
     * 新增|添加巡检
     */
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({path: '/patrolInspection/fireExtinguisher', query: {info: data}});
    },
    /**
     * 修改每页显示条数
     * @param e
     */
    handleSizeChange(e) {
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title {
  font-size: 15px;
  color: #000000
}

.data-content {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
</style>